/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Flex,
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Icon,
} from '@chakra-ui/react';
import SideNav from './side-nav';
import { useState, useEffect } from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AnimatePresence } from 'framer-motion';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Script from 'next/script';
import { useAuth } from '../../contexts/AuthContext';

export interface Link {
  link: string;
  label: string;
  icon: string;
  url: string;
  size: string;
}

export const authNavLinks = {
  home: {
    icon: 'Home',
    size: '1.5rem',
    url: '/account/home',
    label: 'Dashboard',
  },
  transactions: {
    icon: 'Transactions',
    size: '1.5rem',
    url: '/account/transactions',
    label: 'Transactions',
  },
  message: {
    icon: 'Message',
    url: null,
    label: 'Message',
    size: '1.5rem',
  },
  notifications: {
    icon: 'Notifications',
    url: '/account/notifications',
    label: 'Notifications',
    size: '1.5rem',
  },
};
export const anonymousNavLinks = {
  'request-fix': {
    icon: 'Request Fix',
    url: '/request-fix/category',
    label: 'Request Fix',
    size: '1.5rem',
  },
  'device-protect': {
    icon: 'Device Protection',
    url: '/device-care/plan',
    label: 'Protect Your Device',
    size: '1.5rem',
  },
};

const DashBoardLayout = ({ children }: BoxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLoggedIn } = useAuth() as { isLoggedIn: boolean };

  const handleResize = () => {
    if (window.innerWidth >= 768 && isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isOpen]);

  return (
    <>
      <Flex>
        <Box display={{ md: 'none' }}>
          <Button
            onClick={() => setIsOpen(!isOpen)}
            pos="absolute"
            zIndex="100000"
            right={0}
            top="15px"
            bgColor="transparent"
            _active={{ bg: 'transparent', outline: 'none' }}
            _hover={{ bg: 'transparent', outline: 'none' }}
          >
            <Icon
              as={RxHamburgerMenu}
              bg="transparent"
              color={isOpen ? 'white' : '#0F61D6'}
            />
          </Button>
          <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <DrawerOverlay />
            <DrawerContent w="280px">
              <DrawerBody>
                <SideNav
                  links={
                    isLoggedIn
                      ? (Object.values(authNavLinks) as Link[])
                      : (Object.values(anonymousNavLinks) as Link[])
                  }
                />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>

        <Box display={{ base: 'none', md: 'block' }}>
          <SideNav
            links={
              isLoggedIn
                ? (Object.values(authNavLinks) as Link[])
                : (Object.values(anonymousNavLinks) as Link[])
            }
          />
        </Box>

        <Box flex={1} ml={{ md: '280px' }}>
          <Box position="relative">
            <Box
              // {...rest}
              width={{ base: '100%', md: 'calc(100vw - 280px)' }}
              as="main"
              mx="auto"
              height="100vh"
              position="fixed"
              overflowY="auto"
              backgroundColor="white"
              pb={74}
            >
              <AnimatePresence
                mode="wait"
                initial={false}
                onExitComplete={() => window.scrollTo(0, 0)}
              >
                {children}
              </AnimatePresence>
            </Box>
          </Box>
        </Box>
      </Flex>
      {/* <Script
        id="simpu-widget"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
      var widget = window.Simpu.default.init({
        app_id: "${process.env.NEXT_PUBLIC_SIMPU_APP_ID}",
        public_key: "spk__OnLYp2ch7xGJ85qiurGpJ0AkYXuMSxZIruCYrbRC2MN5BSOwXb"
      });
      widget.render({
          name: "${user?.first_name} ${user.last_name}",
          email: "${user.email}",
          user_id: "${user.id}",
       });
      `,
        }}
      /> */}
    </>
  );
};

export default DashBoardLayout;
