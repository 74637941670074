import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalContentProps,
  TextProps,
  ModalHeaderProps,
  ModalProps,
} from '@chakra-ui/react';

interface Props extends ModalContentProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  header?: string;
  closeBtn: boolean;
  headerTextPos?: TextProps;
  headerProps?: ModalHeaderProps;
  modalProps?: ModalProps;
}

const ModalComponent: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  header,
  headerTextPos,
  headerProps,
  closeBtn,
  modalProps,
  ...modalContentProps
}) => {
  const [visibility, setVisibility] = useState(false);
  useEffect(() => {
    isOpen ? setVisibility(true) : setVisibility(false);
  }, [isOpen]);

  if (!visibility) {
    return null;
  }


  return (
    <Modal isOpen={visibility} onClose={onClose} {...modalProps}>
      <ModalOverlay bg="#0A2429" opacity="0.5 !important" />
      <ModalContent
        alignSelf="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...modalContentProps}
      >
        {header && (
          <ModalHeader {...headerProps}>
            {closeBtn && <ModalCloseButton onClick={onClose} />}
            <Text {...headerTextPos}>{header}</Text>
          </ModalHeader>
        )}
        <ModalBody py={8} w="100%">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
